import { makeStyles, ThemeProvider } from '@material-ui/core';
import React, { FC } from 'react';
import { OptionLayout } from '../../../components/settings/layouts/optionLayout';
import { settingsTheme } from '../../../components/settings/theme/settingsTheme';
import { UIContextProvider } from '../../../modules/general/ui';
import { AdminMenuContextProvider } from '../../../modules/settings';

const useStyles = makeStyles(() => ({
  wrapper: {
    position: 'absolute',
    top: 0,
    left: 0,
    height: '100%',
    width: '100%',
  },
}));
const SettingsOptionPage: FC = () => {
  const classes = useStyles();

  return (
    <AdminMenuContextProvider>
      <UIContextProvider>
        <ThemeProvider theme={settingsTheme}>
          <div className={classes.wrapper}>
            <OptionLayout />
          </div>
        </ThemeProvider>
      </UIContextProvider>
    </AdminMenuContextProvider>
  );
};

export default SettingsOptionPage;

import { makeStyles, Typography } from '@material-ui/core';
import React, { FC, KeyboardEvent, useContext, useEffect, useState } from 'react';
import nochoice from '../../../images/settings/edit-option.svg';
import { UIContext } from '../../../modules/general/ui';
import { adminMenuActions, AdminMenuContext, adminMenuOperations } from '../../../modules/settings';
import { findNextTabStop } from '../../../utils/findNextTabStop';
import { FunctionsMenuButton } from '../../general/parts/functionsMenuButton';
import { ClassName } from '../../general/props/classname';
import { AdminButton } from '../../general/units/adminButton';
import { AdminInput } from '../parts/adminInput';
import { ConfirmDialog } from './confirmDialog';
import { DeleteOptionGroupButton } from './deleteOptionGroupButton';
import { EditOptionsTable } from './editOptionsTable';

type Props = ClassName;

const useStyles = makeStyles((theme) => ({
  wrapper: {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    marginLeft: '2rem',
  },
  selectingOptionGroupContents: {
    width: '100%',
    height: '100%',
    overflowY: 'scroll',
  },
  sectionWrapper: {
    width: '100%',
    margin: '2rem 0 1.5rem',
  },
  title: { marginTop: '2rem' },
  instruction: {
    color: theme.palette.text.primary,
    marginBottom: '1.5rem',
  },
  subTitle: {
    margin: '1rem auto',
  },
  textField: {
    fontSize: '1.5rem',
    width: '30rem',
    '@media (max-width: 1024px)': {
      width: '25rem',
    },
  },
  input: {
    color: '#333333',
  },
  errMsgWrapper: { height: '0.5rem', marginTop: '0.5rem' },
  errMsg: { color: 'red', fontSize: '0.8rem', marginBottom: '0.5rem' },
  table: {
    flexGrow: 1,
    // Gridで12分割された内の10のwidthが他フォーム要素と統一されるよう算出
    width: 'calc(30rem / (5/6))',
    '@media (max-width: 1024px)': {
      width: 'calc(25rem / (5/6))',
    },
  },
  optionGroupNameWrap: {
    // tableに合わせてwidthを設定
    width: 'calc(30rem / (5/6))',
    '@media (max-width: 1024px)': {
      width: 'calc(25rem / (5/6))',
    },
  },
  deleteButton: {
    // tableで12分割されたGridの内の2をwidthとして設定
    width: 'calc((30rem / (5/6) / 6) * 0.3)',
    marginTop: '0.3rem',
    marginLeft: '1rem',
    '@media (max-width: 1024px)': {
      width: 'calc((25rem / (5/6) / 6) * 0.3)',
      marginTop: '0.5rem',
    },
  },
  registerButtonWrap: {
    width: '30rem',
    '@media (max-width: 1024px)': {
      width: '25rem',
    },
  },
  registerButton: {
    width: '100%',
    height: '50px',
    marginBottom: '2rem',
  },
  unselectMessageWrapper: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  functionsMenuButtonWrapper: {
    margin: '2rem 2rem 0 auto',
  },
  imageContent: {
    width: '40%',
    minWidth: '30rem',
    '@media (max-width: 1024px)': {
      minWidth: '25rem',
    },
  },
  image: {
    width: '100%',
    opacity: '0.3',
  },
  unselectMessage: {
    color: theme.palette.text.primary,
    '@media (max-width: 1024px)': {
      fontSize: '0.8rem',
    },
    textAlign: 'center',
    marginTop: '-3rem',
  },
  message: {
    color: theme.palette.text.primary,
    marginTop: '-3rem',
  },
}));

export const EditOptionGroupForm: FC<Props> = ({ className }) => {
  const classes = useStyles();

  const uiStore = useContext(UIContext);
  const adminMenuStore = useContext(AdminMenuContext);

  const [optionGroupName, setOptionGroupName] = useState('');

  const [optionGroupNameErrMsg, setOptionGroupNameErrMsg] = useState('');
  const optionGroupNameNotInput = 'オプション名を入力してください';

  const onChangeOptionGroupName = (value: string) => {
    if (value.length <= 20) {
      setOptionGroupName(value);
      setOptionGroupNameErrMsg('');
    }
    if (value.length === 0) {
      setOptionGroupNameErrMsg(optionGroupNameNotInput);
    }
  };

  const [maxSelectionCount, setMaxSelectionCount] = useState('1');

  const onChangeMaxSelectionCount = (value: string) => {
    const confirmedMaxSelectionCount = value === '' ? '0' : parseInt(value, 10).toString();
    if (confirmedMaxSelectionCount.length < 10) {
      setMaxSelectionCount(confirmedMaxSelectionCount);
    }
  };

  const [optionItems, setOptionItems] = useState([{ id: 1, name: '', price: '0' }]);
  const [optionNameBlurFlg, setOptionNameBlurFlg] = useState(true);
  const [isAutoFocusOptionName, setIsAutoFocusOptionName] = useState(false);

  useEffect(() => {
    if (!adminMenuStore.state.selectingOptionGroup) {
      return;
    }
    setOptionGroupName(adminMenuStore.state.selectingOptionGroup.name);
    const count =
      adminMenuStore.state.selectingOptionGroup.maxSelectionCount < 1
        ? '1'
        : adminMenuStore.state.selectingOptionGroup.maxSelectionCount.toString();
    setMaxSelectionCount(count);
    setOptionItems(
      adminMenuStore.state.selectingOptionGroup.options.map((option) => ({
        id: option.id,
        name: option.name,
        price: option.price.toString(),
      }))
    );
    setOptionGroupNameErrMsg('');
    setOptionNameBlurFlg(true);
    setIsAutoFocusOptionName(false);
  }, [adminMenuStore.state.selectingOptionGroup]);

  const createOption = () => {
    if (!optionItems.find((optionItem) => optionItem.name.length === 0))
      if (adminMenuStore.state.selectingOptionGroup) {
        adminMenuOperations.createOption(uiStore.dispatch, adminMenuStore.dispatch, {
          name: optionGroupName,
          maxSelectionCount: parseInt(maxSelectionCount, 10),
          minSelectionCount: adminMenuStore.state.selectingOptionGroup.minSelectionCount,
          options: optionItems.flatMap((optionItem) => [
            { id: optionItem.id, name: optionItem.name, price: parseInt(optionItem.price, 10) },
          ]),
        });
      }
  };

  const updateOption = () => {
    if (adminMenuStore.state.selectingOptionGroup) {
      adminMenuOperations.updateOption(
        uiStore.dispatch,
        adminMenuStore.dispatch,
        adminMenuStore.state.selectingOptionGroup.id,
        {
          name: optionGroupName,
          maxSelectionCount: parseInt(maxSelectionCount, 10),
          minSelectionCount: adminMenuStore.state.selectingOptionGroup.minSelectionCount,
          options: optionItems.flatMap((optionItem) => [
            { id: optionItem.id, name: optionItem.name, price: parseInt(optionItem.price, 10) },
          ]),
        }
      );
    }
    setOptionNameBlurFlg(true);
  };

  const onDeleteOptionGroupClick = () => {
    if (adminMenuStore.state.selectingOptionGroup) {
      adminMenuOperations.deleteOption(uiStore.dispatch, adminMenuStore.dispatch, {
        optionGroupId: adminMenuStore.state.selectingOptionGroup.id,
      });
      adminMenuStore.dispatch(adminMenuActions.optionGroupClicked(undefined));
    }
  };

  const [isOpen, setIsOpen] = useState(false);

  const onOpen = () => {
    setIsOpen(true);
  };

  const onClose = () => {
    setIsOpen(false);
  };

  const onPositiveClick = () => {
    if (optionGroupName.length === 0) {
      setOptionGroupNameErrMsg(optionGroupNameNotInput);
    }
    if (adminMenuStore.state.selectingOptionGroup) {
      if (adminMenuStore.state.selectingOptionGroup.id === 0) {
        setOptionNameBlurFlg(false);
        createOption();
      } else if (!optionItems.find((optionItem) => optionItem.name.length === 0 || optionItem.price.length === 0))
        onOpen();
    }
  };

  const onConfirm = () => {
    updateOption();
    onClose();
  };

  const onChangeOptionName = (optionItemId: number, newOptionName: string) => {
    if (newOptionName.length <= 20) {
      setOptionItems(
        optionItems.map((optionItem) => {
          return {
            ...optionItem,
            name: optionItem.id === optionItemId ? newOptionName : optionItem.name,
          };
        })
      );
    }
  };

  const onBlurOptionName = () => {
    setOptionNameBlurFlg(false);
  };

  const validateOptionPrice = (newOptionPrice: string): string => {
    const reg = /^[+,-]?([1-9]\d*|0)$/;
    const parseNewOptionPrice = parseInt(newOptionPrice, 10);
    if (!reg.test(parseNewOptionPrice.toString())) {
      return '';
    }
    return parseNewOptionPrice === 0 ? '0' : parseNewOptionPrice.toString();
  };

  const onChangeOptionPrice = (optionItemId: number, newOptionPrice: string) => {
    const confirmedOptionPrice = validateOptionPrice(newOptionPrice);
    if (confirmedOptionPrice.length < 9) {
      setOptionItems(
        optionItems.map((optionItem) => {
          return {
            ...optionItem,
            price: optionItem.id === optionItemId ? confirmedOptionPrice : optionItem.price,
          };
        })
      );
    }
  };

  const addOptionItemRow = () => {
    if (!optionItems.find((optionItem) => optionItem.name.length === 0)) {
      setOptionNameBlurFlg(true);
    }
    setIsAutoFocusOptionName(true);
    setOptionItems([
      ...optionItems,
      {
        id: optionItems.length ? optionItems[optionItems.length - 1].id + 1 : 0,
        name: '',
        price: '0',
      },
    ]);
  };

  const onEnterDown = (e: KeyboardEvent<HTMLDivElement>) => {
    if (e.key === 'Enter') {
      findNextTabStop(e.target).focus();
    }
  };

  const isDisabled = () => {
    return (
      parseInt(maxSelectionCount, 10) < 1 ||
      maxSelectionCount.length === 0 ||
      (optionItems.find((optionItem) => optionItem.name.length === 0 || optionItem.price.length === 0) &&
        !optionNameBlurFlg) ||
      !!optionItems.find((optionItem) => optionItem.price.length === 0) ||
      optionGroupNameErrMsg !== '' ||
      optionItems.some((optionItem) =>
        optionItems.some(
          (verifyOptionItem) => optionItem.id !== verifyOptionItem.id && verifyOptionItem.name === optionItem.name
        )
      )
    );
  };

  return (
    <>
      {adminMenuStore.state.selectingOptionGroup ? (
        <div className={`${classes.wrapper} ${className}`}>
          <FunctionsMenuButton className={classes.functionsMenuButtonWrapper} />
          <div className={classes.selectingOptionGroupContents}>
            <section className={classes.sectionWrapper}>
              <Typography variant="h5" className={classes.title}>
                {adminMenuStore.state.selectingOptionGroup.minSelectionCount === 1
                  ? '必須注文オプション名'
                  : '任意注文オプション名'}
              </Typography>
              <Typography variant="body2" className={classes.instruction}>
                {adminMenuStore.state.selectingOptionGroup.minSelectionCount === 1
                  ? '※注文時に、必ず１つ以上の項目の選択が必要となるオプションです。'
                  : '※お客様の任意でご注文頂くオプションです。'}
              </Typography>
              <div className={classes.optionGroupNameWrap}>
                <AdminInput
                  className={`${classes.input} ${classes.textField}`}
                  value={optionGroupName}
                  placeholder="オプション名"
                  onChange={onChangeOptionGroupName}
                  onKeyDown={onEnterDown}
                />
                {adminMenuStore.state.selectingOptionGroup.id !== 0 && (
                  <DeleteOptionGroupButton
                    className={classes.deleteButton}
                    targetOptionGroupName={adminMenuStore.state.selectingOptionGroup.name}
                    onDeleteOptionGroupClick={onDeleteOptionGroupClick}
                  />
                )}
                <div className={classes.errMsgWrapper}>
                  <p className={classes.errMsg}>{optionGroupNameErrMsg}</p>
                </div>
              </div>
            </section>
            <section className={classes.sectionWrapper}>
              <Typography variant="h5" className={classes.subTitle}>
                オプション内容
              </Typography>
              <EditOptionsTable
                className={classes.table}
                optionItems={optionItems}
                autoFocusOptionName={isAutoFocusOptionName}
                onChangeOptionName={onChangeOptionName}
                onBlurOptionName={onBlurOptionName}
                onChangeOptionPrice={onChangeOptionPrice}
                setOptionItems={setOptionItems}
                addOptionItemRow={addOptionItemRow}
              />
              <div className={classes.errMsgWrapper}>
                {optionItems.find((optionItem) => optionItem.name.length === 0) && !optionNameBlurFlg && (
                  <Typography className={classes.errMsg} variant="body2">
                    オプション項目名を入力してください
                  </Typography>
                )}
                {optionItems.find((optionItem) => optionItem.price.length === 0) && (
                  <Typography className={classes.errMsg} variant="body2">
                    オプション価格を入力してください
                  </Typography>
                )}
                {optionItems.some((optionItem) =>
                  optionItems.some(
                    (verifyOptionItem) =>
                      optionItem.id !== verifyOptionItem.id &&
                      verifyOptionItem.name === optionItem.name &&
                      optionItem.name !== ''
                  )
                ) && (
                  <Typography className={classes.errMsg} variant="body2">
                    オプション名が重複しています
                  </Typography>
                )}
              </div>
              <div className={classes.errMsgWrapper} />
            </section>
            <section className={classes.sectionWrapper}>
              <Typography variant="h5" className={classes.subTitle}>
                注文上限数
              </Typography>
              <div>
                <AdminInput
                  type="number"
                  value={maxSelectionCount}
                  min={1}
                  onChange={onChangeMaxSelectionCount}
                  onKeyDown={onEnterDown}
                />
                <div className={classes.errMsgWrapper}>
                  {(parseInt(maxSelectionCount, 10) < 1 || maxSelectionCount.length === 0) && (
                    <p className={classes.errMsg}>1以上の数値を入力してください</p>
                  )}
                </div>
              </div>
            </section>
            <div className={classes.registerButtonWrap}>
              <AdminButton
                className={classes.registerButton}
                color="positive"
                text="保存"
                onClick={onPositiveClick}
                disabled={isDisabled()}
              />
            </div>
            <ConfirmDialog
              open={isOpen}
              messages={['このオプションを設定している', '商品すべてに変更が反映されます。']}
              onClose={onClose}
              onConfirm={onConfirm}
            />
          </div>
        </div>
      ) : (
        <div className={`${classes.unselectMessageWrapper} ${className}`}>
          <FunctionsMenuButton className={classes.functionsMenuButtonWrapper} />
          <div className={classes.imageContent}>
            <img src={nochoice} alt="オプションを選んでください。" className={classes.image} />
            <Typography variant="body1" className={classes.unselectMessage}>
              オプションを、 左メニューより選択してください。
            </Typography>
          </div>
        </div>
      )}
    </>
  );
};

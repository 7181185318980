import { InputAdornment, Grid, makeStyles, Paper, TextField } from '@material-ui/core';
import withStyles from '@material-ui/core/styles/withStyles';
import React, { FC, KeyboardEvent } from 'react';
import { findNextTabStop } from '../../../utils/findNextTabStop';
import { ClassName } from '../../general/props/classname';
import { AdminIconButton } from '../parts/adminIconButton';

type Props = ClassName & {
  optionItem: { id: number; name: string; price: string };
  autoFocusOptionName: boolean;
  onChangeOptionName: (optionItemId: number, newOptionName: string) => void;
  onBlurOptionName: () => void;
  onChangeOptionPrice: (optionItemId: number, newOptionPrice: string) => void;
  deleteOptionItem: (optionItemId: number) => void;
};

const StyledTextField = withStyles({
  root: {
    backGround: '#ededed',
    '& .MuiInput-underline:before': {
      border: 'none',
    },
    '& .MuiInput-underline:after': {
      border: 'none',
    },
    '& .MuiInput-underline:hover:not(.Mui-disabled):before': {
      border: 'none',
    },
    '& input::-webkit-inner-spin-button': {
      display: 'none',
    },
  },
})(TextField);

const useStyles = makeStyles((theme) => ({
  paperBody: {
    padding: '0.6rem 1rem',
    textAlign: 'left',
    color: theme.palette.text.secondary,
    borderRadius: '0',
  },
  input: { color: '#333333', min: 0 },
  deleteButton: {
    width: '30%',
    marginTop: '0.5rem',
    marginLeft: '1rem',
    '@media (max-width: 1024px)': {
      width: 'calc((25rem / (5/6) / 6) * 0.3)',
      marginTop: '0.8rem',
    },
  },
}));

export const EditOptionRow: FC<Props> = ({
  className,
  optionItem,
  autoFocusOptionName,
  onChangeOptionName,
  onBlurOptionName,
  onChangeOptionPrice,
  deleteOptionItem,
}) => {
  const classes = useStyles();

  const handleChangeOptionName = (event: React.ChangeEvent<HTMLInputElement>) => {
    onChangeOptionName(optionItem.id, event.target.value);
  };

  const handleChangeOptionPrice = (event: React.ChangeEvent<HTMLInputElement>) => {
    onChangeOptionPrice(optionItem.id, event.target.value);
  };

  const onEnterDown = (e: KeyboardEvent<HTMLDivElement>) => {
    if (e.key === 'Enter') {
      findNextTabStop(e.target).focus();
    }
  };

  return (
    <>
      <Grid item xs={5}>
        <Paper className={classes.paperBody}>
          <TextField
            InputProps={{
              disableUnderline: true,
            }}
            size="small"
            value={optionItem.name}
            placeholder="項目名"
            onChange={handleChangeOptionName}
            onKeyDown={onEnterDown}
            onBlur={onBlurOptionName}
            autoFocus={autoFocusOptionName}
          />
        </Paper>
      </Grid>
      <Grid item xs={5}>
        <Paper className={classes.paperBody}>
          <StyledTextField
            InputProps={{
              classes: {
                input: classes.input,
              },
              startAdornment: <InputAdornment position="start">￥</InputAdornment>,
            }}
            size="small"
            type="number"
            value={optionItem.price}
            placeholder="価格"
            onChange={handleChangeOptionPrice}
            onKeyDown={onEnterDown}
          />
        </Paper>
      </Grid>
      <Grid item xs={2}>
        <AdminIconButton
          className={classes.deleteButton}
          iconType="delete"
          tabIndex={-1}
          onClick={() => deleteOptionItem(optionItem.id)}
        />
      </Grid>
    </>
  );
};

import { makeStyles } from '@material-ui/core';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import { DTOOptionGroup } from 'models';
import React, { FC } from 'react';
import { ClassName } from '../../general/props/classname';
import { IconTextButton } from '../parts/iconTextButton';
import { OptionGroupsListItem } from './optionGroupsListItem';

type Props = ClassName & {
  requiredOrder: boolean;
  optionGroups: DTOOptionGroup[];
  onOptionGroupClick: (optionGroup: DTOOptionGroup, requiredOrder: boolean) => void;
};

const useStyles = makeStyles((theme) => ({
  optionListWrapper: {},
  iconButton: {
    width: '100%',
    textAlign: 'center',
    paddingTop: '1.5rem',
  },
  icon: {
    marginRight: '5px',
  },
}));

export const OptionGroupsList: FC<Props> = ({ className, requiredOrder, optionGroups, onOptionGroupClick }) => {
  const classes = useStyles();

  const initialOptionGroup = {
    id: 0,
    name: '',
    maxSelectionCount: 0,
    minSelectionCount: 0,
    options: [{ id: 0, name: '', price: 0 }],
  };

  return (
    <>
      <div className={`${classes.optionListWrapper} ${className}`}>
        {optionGroups.map((optionGroup) => {
          return (
            !!optionGroup.minSelectionCount === requiredOrder && (
              <OptionGroupsListItem
                key={optionGroup.id}
                requiredOrder={requiredOrder}
                optionGroup={optionGroup}
                onOptionGroupClick={onOptionGroupClick}
              />
            )
          );
        })}
        <div className={classes.iconButton}>
          <IconTextButton
            icon={<AddCircleOutlineIcon className={classes.icon} />}
            text="オプションを追加"
            onClick={() => onOptionGroupClick(initialOptionGroup, requiredOrder)}
          />
        </div>
      </div>
    </>
  );
};

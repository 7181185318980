import { ButtonBase, makeStyles, Typography } from '@material-ui/core';
import { DTOOptionGroup } from 'models';
import React, { FC, useContext } from 'react';
import { AdminMenuContext } from '../../../modules/settings';

type Props = {
  requiredOrder: boolean;
  optionGroup: DTOOptionGroup;
  onOptionGroupClick: (optionGroup: DTOOptionGroup, requiredOrder: boolean) => void;
};

const useStyles = makeStyles((theme) => ({
  button: {
    display: 'flex',
    width: '100%',
    padding: '0.4rem',
    color: theme.palette.text.primary,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    cursor: 'pointer',
    textAlign: 'left',
    borderBottom: '1px solid #fff',
    '&:hover': {
      color: theme.palette.text.primary,
      backgroundColor: theme.sideMenu?.hover.background,
    },
    '&:active': {
      backgroundColor: theme.sideMenu?.hover.background,
    },
  },
  selected: {
    backgroundColor: theme.sideMenu?.hover.background,
  },
}));

export const OptionGroupsListItem: FC<Props> = ({ requiredOrder, optionGroup, onOptionGroupClick }) => {
  const classes = useStyles();

  const adminMenuStore = useContext(AdminMenuContext);
  const isSelected = adminMenuStore.state.selectingOptionGroup?.id === optionGroup.id;

  const handleOptionGroupClick = () => {
    onOptionGroupClick(optionGroup, requiredOrder);
  };

  return (
    <ButtonBase
      className={`${classes.button} ${isSelected && classes.selected}`}
      onClick={handleOptionGroupClick}
      onFocus={handleOptionGroupClick}
    >
      <Typography>{optionGroup.name}</Typography>
    </ButtonBase>
  );
};

import { makeStyles, Typography } from '@material-ui/core';
import { DTOOptionGroup } from 'models';
import React, { FC, useContext } from 'react';
import { adminMenuActions, AdminMenuContext } from '../../../modules/settings';
import { ClassName } from '../../general/props/classname';
import { OptionGroupsList } from './optionGroupsList';

type Props = ClassName;

const useStyles = makeStyles((theme) => ({
  wrapper: {
    height: '100%',
    backgroundColor: theme.sideMenu?.background,
    borderRight: '1px solid #cbcbcb',
    overflowY: 'auto',
  },
  titleWrapper: {
    width: '100%',
    color: '#ffffff',
    backgroundColor: theme.sideMenu?.title.background,
  },
  title: {
    textAlign: 'left',
    padding: '0.6rem',
    width: '85%',
  },
  requiredOptions: {
    paddingBottom: '1.5rem',
  },
  optionalOptions: {
    paddingBottom: '20vh',
  },
}));

export const OptionSideMenu: FC<Props> = ({ className }) => {
  const classes = useStyles();

  const adminMenuStore = useContext(AdminMenuContext);

  const dispatchOptionGroupClicked = (optionGroup: DTOOptionGroup, requiredOrder: boolean) => {
    adminMenuStore.dispatch(
      adminMenuActions.optionGroupClicked({ ...optionGroup, minSelectionCount: requiredOrder ? 1 : 0 })
    );
  };

  return (
    <div className={`${classes.wrapper} ${className}`}>
      <div className={classes.titleWrapper}>
        <Typography className={classes.title}>必須注文オプション</Typography>
      </div>
      <OptionGroupsList
        className={classes.requiredOptions}
        requiredOrder
        optionGroups={adminMenuStore.state.optionGroups}
        onOptionGroupClick={dispatchOptionGroupClicked}
      />
      <div className={classes.titleWrapper}>
        <Typography className={classes.title}>任意注文オプション</Typography>
      </div>
      <OptionGroupsList
        className={classes.optionalOptions}
        requiredOrder={false}
        optionGroups={adminMenuStore.state.optionGroups}
        onOptionGroupClick={dispatchOptionGroupClicked}
      />
    </div>
  );
};

import React, { FC, useState } from 'react';
import { ClassName } from '../../general/props/classname';
import { AdminIconButton } from '../parts/adminIconButton';
import { ConfirmDialog } from './confirmDialog';

type Props = ClassName & {
  targetOptionGroupName: string;
  onDeleteOptionGroupClick: () => void;
};

export const DeleteOptionGroupButton: FC<Props> = ({ className, targetOptionGroupName, onDeleteOptionGroupClick }) => {
  const [isOptionGroupDeleteDialogOpen, setIsOptionGroupDeleteDialogOpen] = useState(false);

  const onIsOptionGroupDeleteDialogOpen = () => {
    setIsOptionGroupDeleteDialogOpen(true);
  };

  const onIsOptionGroupDeleteDialogClose = () => {
    setIsOptionGroupDeleteDialogOpen(false);
  };

  const handleOptionGroupDelete = () => {
    onDeleteOptionGroupClick();
    onIsOptionGroupDeleteDialogClose();
  };

  return (
    <>
      <AdminIconButton
        className={className}
        iconType="delete"
        onClick={onIsOptionGroupDeleteDialogOpen}
        tabIndex={-1}
      />
      <ConfirmDialog
        open={isOptionGroupDeleteDialogOpen}
        messages={[`${targetOptionGroupName}を削除します`]}
        onClose={onIsOptionGroupDeleteDialogClose}
        onConfirm={handleOptionGroupDelete}
      />
    </>
  );
};

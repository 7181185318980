import { Grid, makeStyles, Paper } from '@material-ui/core';
import React, { FC } from 'react';
import { ClassName } from '../../general/props/classname';
import { AdminIconButton } from '../parts/adminIconButton';
import { EditOptionRow } from './editOptionRow';

type Props = ClassName & {
  optionItems: { id: number; name: string; price: string }[];
  autoFocusOptionName: boolean;
  onChangeOptionName: (optionItemId: number, newOptionName: string) => void;
  onBlurOptionName: () => void;
  onChangeOptionPrice: (optionItemId: number, newOptionPrice: string) => void;
  setOptionItems: (optionItems: { id: number; name: string; price: string }[]) => void;
  addOptionItemRow: () => void;
};

const useStyles = makeStyles((theme) => ({
  paperHead: {
    padding: '1rem 1rem',
    textAlign: 'left',
    color: theme.palette.text.primary,
    borderRadius: '0',
    background: '#c7c7c7',
    fontWeight: 700,
  },
  paperHeadLeft: {
    borderTopLeftRadius: '10px',
  },
  paperHeadRight: {
    borderTopRightRadius: '10px',
  },
  paperBody: {
    padding: '0.6rem 1rem',
    textAlign: 'left',
    color: theme.palette.text.primary,
    borderRadius: '0',
  },
  addRowPaper: {
    padding: '0.6rem 1rem',
    textAlign: 'center',
    borderRadius: '0',
    borderBottomRightRadius: '10px',
    borderBottomLeftRadius: '10px',
  },
  addRowButton: {
    width: '5%',
  },
}));

export const EditOptionsTable: FC<Props> = ({
  className,
  optionItems,
  autoFocusOptionName,
  onChangeOptionName,
  onBlurOptionName,
  onChangeOptionPrice,
  setOptionItems,
  addOptionItemRow,
}) => {
  const classes = useStyles();

  const deleteOptionItem = (optionItemId: number) => {
    if (optionItems.length !== 1) {
      setOptionItems(optionItems.filter((optionItem) => optionItem.id !== optionItemId));
    }
  };

  return (
    <Grid container spacing={0} className={className}>
      <Grid item xs={5}>
        <Paper className={`${classes.paperHead} ${classes.paperHeadLeft}`}>オプション項目</Paper>
      </Grid>
      <Grid item xs={5}>
        <Paper className={`${classes.paperHead} ${classes.paperHeadRight}`}>価格(税抜)</Paper>
      </Grid>
      {optionItems.map((optionItem) => (
        <EditOptionRow
          key={`optionItem-${optionItem.id}`}
          optionItem={optionItem}
          autoFocusOptionName={autoFocusOptionName}
          onChangeOptionName={onChangeOptionName}
          onBlurOptionName={onBlurOptionName}
          onChangeOptionPrice={onChangeOptionPrice}
          deleteOptionItem={deleteOptionItem}
        />
      ))}
      <Grid item xs={10}>
        <Paper className={classes.addRowPaper}>
          <AdminIconButton className={classes.addRowButton} iconType="addGreen" onClick={addOptionItemRow} />
        </Paper>
      </Grid>
    </Grid>
  );
};
